import React from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import SEO from "../components/SEO"
import StoreLayout from "../components/StoreLayout"

const ReactivateAccount = () => {
  return (
    <StoreLayout>
      <SEO
        title="Reactivate Amazon Suspended Account"
        description="Need help reactivate a deactivated Amazon seller account? We help sellers appeal and reinstate their suspended accounts. 100% money-back guarantee."
        keywords={[`Amazon`, `reactivate`, `reinstate`, `reinstatement`, `suspended`, `deactivated`, `appeal`, `account`]}
      />
      <section className="hero section">
        <div className="container section">
          <h1 className="title">Reactivate Amazon suspended account in no time. <br/>Get back to sell now.</h1>
          <div className="subtitle">Appealing to Amazon can take months and still getting nowhere. <br/> Take out the guesswork and let our specialists reactivate your deactivated account. <br/> Free consultation. 100% money-back guarantee.</div>
          <AnchorLink to="#contact"><button className="cta">Contact Us</button></AnchorLink>
        </div>
      </section>
      <section className="section">
        <div className="container section">
          <h3 className="title">Why can't I reactivate my Amazon seller account?</h3>
          <p>Feeling frustrated appealing to Amazon and confused with their canned responses? I feel you.</p>
          <p>When we started selling on Amazon, we got our accounts deactivated once in a quarter, even in some cases we haven't done anything wrong.</p>
          <p>Watching the rankings of our products drop every day with lots of inventory sitting in the warehouse keeps me awake at night.</p>
          <p>What makes me feel even frustrated is that Amazon support only gives canned responses without listening to you or telling you what they want.</p>
          <p>In fact, once you understand what they want reactivating your deactivated account is pretty straightforward.</p>
          <p>However, it takes lots of time and money to learn how to communicate with Amazon.</p>
          <p>Every wasted minute is a wasted dollar.</p>
          <p>Let us help you now with a free consultation.</p>
          <AnchorLink to="#contact"><button className="cta">Contact Us</button></AnchorLink>
        </div>
      </section>
      <section className="section light">
        <div className="container section">
          <h3 className="title">How does it work?</h3>
          <p>We can help you reinstate your Amazon deactivated seller account if your account got suspended due to violations related to one of the following reasons:</p>
          <ul>
            <li>product authenticity, infringement, and intellectual property violations</li>
            <li>used sold as new complaints</li>
            <li>review abuse</li>
            <li>product description</li>
            <li>invalid variation</li>
            <li>selling privileges removed</li>
            <li>product safety</li>
            <li>account health and performance (order defect rate, late shipment rate, cancellation rate)</li>
            <li>sales volumes</li>
          </ul>
          <p>Step 1: Send an email to hello@amazonungating.services with details about your case. (e.g. marketplace, ASIN, reasons of getting account deactivated, violation history, etc.)</p>
          <p>Step 2: We will review your case for free and get back to you if we accept your case.</p>
          <p>Step 3: We provide 100% money-back guarantee if we accept your case. We will appeal to Amazon and manage all the communications for you.</p>
          <AnchorLink to="#contact"><button className="cta">Contact Us</button></AnchorLink>
        </div>
      </section>
    </StoreLayout>
  )
}

export default ReactivateAccount